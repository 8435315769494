<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <a-row>
          <a-col :span="4">
            <div style="font-size: 20px;">
              <span>Cloud call center</span>
            </div>
          </a-col>
          <a-col :span="6">
            <div>
              <span>Sitting state：</span>{{ statusMap[agentStatus] }}
            </div>
          </a-col>
          <a-col :span="6">
            <div v-click-outside="onClickDialOutside">
                <a-input ref="outNumInput" v-model="outNum" @focus="showDial=true" size="large" id="phoneNumInput"
                         placeholder="Please enter the number" style="width: 240px; border-radius: 20px; ">
                  <a-button slot="suffix" :disabled="isCall" type="primary" shape="circle"
                            title="CallOut"
                            style="margin-right: -10px; background: #24b89e; color: #fff;"
                            @click="handleAgentBarBtnClick('makeCall')">
                    <a-icon type="phone"/>
                  </a-button>
                </a-input>
                <div v-show="showDial"
                     style="width: 200px;background: #fff;position: absolute;right:0px;top:64px;padding: 10px 12px;border-radius:0px 0px 4px 4px;box-shadow: 0 2px 8px rgb(0 0 0 / 9%);">
                  <a-row>
                    <a-col v-for="item in numList" :span="8" style="text-align: center;line-height: 50px;">
                      <a-button @click="handleDialBtnClick(item)" shape="circle"
                                style="background: rgba(228,228,230,1);font-weight: bold;">{{ item }}
                      </a-button>
                    </a-col>
                  </a-row>
                </div>

            </div>
          </a-col>
          <a-col :span="4">
            <a-dropdown property="topCenter">
              <div>Seat number {{ agentNo }}
                <a-icon type="down"/>
              </div>
              <a-menu slot="overlay" @click="onClickMenu">
                <a-menu-item key="1">
                  <a-icon type="user"/>
                  Account info
                </a-menu-item>
                <a-menu-item v-if="logoutBtnDisable" key="2">
                  <a-icon type="logout"/>
                  <span style="color: red">Logout</span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-col>
          <a-col :span="4" v-if="loginBtnDisable && isLoggedIn">
            <a-button type="primary" @click="showLogin" ghost>Login</a-button>
          </a-col>
          <a-modal
              title="Account info"
              :visible="showInfoDial"
              @cancel="handleInfoDialCancel"
              :okButtonProps="{'style': {'display': 'none'}}"
          >
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-form-item label="account">
                <a-input v-model="account ? account : config.extNo"/>
              </a-form-item>
              <a-form-item label="password">
                <a-input v-model="password ? password : config.extPwd"/>
              </a-form-item>
            </a-form>
          </a-modal>

          <a-modal
              title="Login"
              :visible="showLoginDial"
              @ok="handleLoginDialCancel"
              @cancel="cancelLoginDialCancel"
              :okButtonProps="account && password ? {} : {'style': {'display': 'none'}}"
              okText="Login"
          >
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <a-form-item label="account">
                <a-input v-model="account" placeholder="please input account"/>
              </a-form-item>
              <a-form-item label="password">
                <a-input v-model="password" type="password" placeholder="please input password"/>
              </a-form-item>
            </a-form>
          </a-modal>
        </a-row>
      </a-layout-header>
      <a-layout>
        <a-layout-sider width="420">
          <a-row>
            <a-col :span="8">
              <div style="font-size: 20px">Call Records: </div>
            </a-col>
            <a-col :span="4" :offset="12">
              <a target="_blank" title="more" href="https://web.antgst.com/voip/callRecord/list">more</a>
            </a-col>
          </a-row>
          <a-list
              class="demo-loadmore-list"
              item-layout="horizontal"
              :data-source="recordList.length ? recordList : showRecord"
          >
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-button slot="actions" :disabled="callBtnDisable" type="primary" shape="circle"
                        title="CallOut"
                        style="background: #24b89e; color: #fff;"
                        @click="makeCall(item.callee)">
                <a-icon type="phone"/>
              </a-button>
              <a-button slot="actions" v-show="item.callId && item.showEditButton" type="primary" shape="circle"
                        title="Edit"
                        style="background: #24b89e; color: #fff;"
                        @click="edit(item)">
                <a-icon type="edit" />
              </a-button>
              <a-list-item-meta
                  :description="new Date(item.startTime).toLocaleString() + ' ' + item.callResult + ' ' + item.duration"
              >
                <div slot="title" style="font-size: 20px;color: #24b89e">{{ item.callee }}</div>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-layout-sider>
        <a-layout-content>
          <a-row style="margin-top: 30px">
            <a-col :span="4">
              <span style="font-size: 20px">Current call</span>
            </a-col>
          </a-row>
          <div v-if="showCall"
              style="margin-top: 40px;margin-left: 60px;margin-right: 60px; padding-top: 20px; border-radius: 6px;background: #e9f8f5">
            <a-row>
              <a-col :span="8">
                <div style="font-size: 20px;color: #24b89e">{{ customerNo }}</div>
                <div style="margin-top: 30px; color: #ccc">{{ 'REGISTERED' === agentStatus ? 'ON_CALL' : agentStatus }}
                  . . . | {{ timerCallString }}
                </div>
              </a-col>

              <a-col :span="8" :offset="8">
                <a-button :disabled="hangupBtnDisable" size="large" shape="circle" title="hangup"
                          @click="handleAgentBarBtnClick('hangup')">
                  <svg t="1717667752044" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1412">
                    <path
                        d="M511.993262 0C229.232612 0 0 229.232612 0 511.993262s229.232612 511.993262 511.993262 511.993262 511.993262-229.232612 511.993262-511.993262S794.753912 0 511.993262 0z m236.092071 625.396697c-93.835783-10.093782-87.83881-45.105323-88.081385-92.339909-0.080858-32.963137-76.168296-40.065171-148.158926-39.822597s-148.078068 7.641087-148.010686 40.604224c0.080858 47.167204 6.145213 82.205698-87.596236 92.730724s-81.855314-57.409226-82.01703-103.134461c-0.161716-52.759887 123.012068-126.408107 317.233138-126.879779 194.531026-0.471672 318.041719 72.54316 318.041719 125.222189 0.121287 45.859999 12.425189 113.632533-81.410594 103.619609z"
                        fill="#E0452B"
                        p-id="1413">

                    </path>
                  </svg>
                </a-button>
              </a-col>
            </a-row>
          </div>
<!--          来电提示-->
          <div
              v-if="callReminderShow"
               style="margin-top: 40px;margin-left: 60px;margin-right: 60px; padding-top: 20px; border-radius: 6px;background: #e9f8f5;" >
            <a-row :gutter="24">
              <a-col :span=8>
                <div style="font-size: 20px;color: #24b89e">{{ customerNo }}</div>
                <div style="margin-top: 30px; color: #ccc">{{ 'REGISTERED' === agentStatus ? 'INCOMING_CALL' : agentStatus }}
                  . . . | {{ timerCallString }}
                </div>
              </a-col>

              <a-col :span="1" :offset="11" >
                <a-button  size="large" shape="circle" title="hangup"
                          @click="handleAgentBarBtnClick('hangup')">
                  <svg t="1717667752044" class="icon" viewBox="0 0 1024 1024" version="1.1"
                       xmlns="http://www.w3.org/2000/svg" p-id="1412">
                    <path
                        d="M511.993262 0C229.232612 0 0 229.232612 0 511.993262s229.232612 511.993262 511.993262 511.993262 511.993262-229.232612 511.993262-511.993262S794.753912 0 511.993262 0z m236.092071 625.396697c-93.835783-10.093782-87.83881-45.105323-88.081385-92.339909-0.080858-32.963137-76.168296-40.065171-148.158926-39.822597s-148.078068 7.641087-148.010686 40.604224c0.080858 47.167204 6.145213 82.205698-87.596236 92.730724s-81.855314-57.409226-82.01703-103.134461c-0.161716-52.759887 123.012068-126.408107 317.233138-126.879779 194.531026-0.471672 318.041719 72.54316 318.041719 125.222189 0.121287 45.859999 12.425189 113.632533-81.410594 103.619609z"
                        fill="#E0452B"
                        p-id="1413">

                    </path>
                  </svg>
                </a-button>
              </a-col>
              <a-col  :span="1" :offset="1">
                <a-button  v-if="answerButton" type="primary" shape="circle"
                           size="large"  title="Answer"
                           style="margin-left: 20px; background: #24b89e; color: #fff;"
                           @click="handleAgentBarBtnClick('answer')">
                  <a-icon type="phone"  />
                </a-button>
              </a-col>
            </a-row>
          </div>
<!--          提交表单-->
          <div id="answerForm"  v-show="answerFormShow" >
            <a-form :form="form" style="max-width: 65%; margin: -100px auto;  background-color: #b4daee; border-radius: 10px;  "  >
                              <a-form-item label="currentCallerAccount" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input style="width: 80%"   v-model="account" readOnly />
                              </a-form-item>
                              <a-form-item label="userCallerAccount" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input style="width: 80%" v-model="customerNo" readOnly />
                              </a-form-item>
                              <a-form-item label="isMark" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                                <a-radio-group v-model="isMarker" v-decorator="[ 'isMarker', validatorRules.isMarker]">
                                  <a-radio :value=2><span>Yes</span></a-radio>
                                  <a-radio :value=1><span>No</span></a-radio>
                                </a-radio-group>
                              </a-form-item>

                              <a-form-item label="customerClassification" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                                <a-radio-group v-model="customerClassification" v-decorator="[ 'customerClassification', validatorRules.customerClassification]">
                                  <a-radio value='A'><span>A</span></a-radio>
                                  <a-radio value='B'><span>B</span></a-radio>
                                  <a-radio value='C'><span>C</span></a-radio>
                                  <a-radio value='D'><span>D</span></a-radio>
                                  <a-radio value='E'><span>E</span></a-radio>
                                </a-radio-group>
                              </a-form-item>
                              <a-form-item label="remark" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-textarea style="width: 80%"  v-model="remark" allow-clear v-decorator="['remark']"/>
                              </a-form-item>
                              <a-form-item>
                                <a-button type="primary"  @click="handleCancel" style="margin-right: 16px;"  v-if="answerCancel">
                                  <span>Cancel</span>
                                </a-button>
                              <!-- 提交按钮 -->
                                <a-button  type="primary" html-type="submit" @click="handleSubmit"  v-if="answerSubmit" >
                                  <span >Submit</span>
                                </a-button>
                              </a-form-item>
              <a-divider>Submit Form</a-divider>
            </a-form>
          </div>
          <a-modal v-model="visible" title="Confirm" ok-text="Continue" cancel-text="Cancel" @ok="ConfirmModal" @cancel="hideModal">
                    <p> The current form has not been submitted yet, Do you want to continue ? </p>
          </a-modal>

          <div id="audio">
          </div>
        </a-layout-content>

      </a-layout>
    </a-layout>

  </div>

</template>

<script>
import {directive as clickOutside} from 'v-click-outside-x';
import axios from 'axios';
import {message} from "ant-design-vue";
import ATextarea from 'ant-design-vue/es/input/TextArea'

export default {
  name: 'AgentBar',
  directives: {clickOutside},
  components:{
    ATextarea,
  },
    data() {
        return {
            isPredictiveOutboundCall:false,
            recordList: [],
            customerClassification: '',
            remark: '',
            isMarker: '',
            //挂断时间
            endTime: '',
            // 记录本次呼叫是否接通
            isSwitchOn: false,
            // 呼叫时间
            makeCallTime: null,
            // 实际通话时长
            realDuration: '',
            // 接通时间
            responseTime: null,
            callResult: '',
            queryParam: [],
            visible: false,
            answerFormShow: false,
            answerCancel: false,
            answerButton: true,
            timeCall: null,
            callDuration: 0,
            showCall: false,
            isCall: false,
            showLoginDial: false,
            showInfoDial: false,
            isLoggedIn: true,
            account: '',
            password: '',
            //初始化SDK所需要的配置
            config: {
                host: 'wssip.antgst.com',
                port: '7443',
                proto: true,
                extNo: '',
                extPwd: '',
                autoRegister: false,
                debug: true,
                //stunServer: 'stun.1.google.com',   可自行修改使用stun服务器地址
                stateEventListener: this.stateEventListener
            },
            //坐席分机号
            agentNo: '',
            //客户号码
            customerNo: '',
            //拨号弹窗
            showDial: false,
            isAnswered: false,
            formData: '',
            //转接弹窗
            showTransferDial: false,
            form: this.$form.createForm(this),
            callReminderShow: false,
            //转接号码
            transNum: '',
            numList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#',],
            agentStatus: 'DISCONNECTED',
            callId: '',
            statusMap: {
                CONNECTED: 'CONNECTED',
                DISCONNECTED: 'DISCONNECTED',
                REGISTERED: 'REGISTERED',
                UNREGISTERED: 'UNREGISTERED',
                REGISTER_FAILED: 'REGISTER_FAILED',
                IN_CALL: 'IN_CALL',
                INCOMING_CALL: 'INCOMING_CALL',
                OUTGOING_CALL: 'OUTGOING_CALL',
                HOLD: 'HOLD',
                CALL_END: 'CALL_END'
            },
            labelCol: {
                xs: {span: 8},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 10},
                sm: {span: 10},
            },
            validatorRules: {
                isMarker: {
                    rules: [
                        {required: true, message: "please select isMarker"}
                    ]
                },
                customerClassification: {
                    rules: [
                        {required: true, message: "please select customerClassification"}
                    ]
                }
            },
            timer: null,
            timerString: '00:00:00',
            outNum: '',
            answerSubmit: false,
        }
    },
  computed: {
    showRecord() {
      const tmp = localStorage.getItem('wssip_record')
      if (tmp) {
        return JSON.parse(tmp)
      }
    },
    timerCallString() {
      const hour = Math.floor(this.callDuration / 3600).toString().padStart(2, '0');
      const minutes = String(Math.floor(this.callDuration / 60)).padStart(2, '0');
      const seconds = String(this.callDuration % 60).padStart(2, '0');
      return `${hour}:${minutes}:${seconds}`;
    },
    loginBtnDisable() {
      return !['CONNECTED'].includes(this.agentStatus)
    },
    logoutBtnDisable() {
      return ['REGISTERED', 'CALL_END'].includes(this.agentStatus)
    },
    callBtnDisable() {
      return !['REGISTERED', 'CALL_END'].includes(this.agentStatus)
    },
    answerBtnDisable() {
      return !['INCOMING_CALL'].includes(this.agentStatus)
    },
    hangupBtnDisable() {
      return !['IN_CALL', 'HOLD', 'OUTGOING_CALL', 'INCOMING_CALL'].includes(this.agentStatus)
    },
    holdBtnDisable() {
      return !['IN_CALL'].includes(this.agentStatus)
    },
    unHoldBtnDisable() {
      return !['HOLD'].includes(this.agentStatus)
    },
    transferBtnDisable() {
      return !['IN_CALL'].includes(this.agentStatus)
    },
  },
  methods: {
    ConfirmModal(){
      this.visible=false;
      // 应答
      this.handleCancel();
      this.answerPhone();
    },
    hideModal(){
      this.visible=false;
    },
    handleCancel(){
      this.form.resetFields();
      this.answerFormShow=false;
      this.answerCancel=false;
      this.answerSubmit=false;
    },
    edit(record){
      this.form.resetFields();
      this.customerNo=record.callee;
      this.callId=record.callId;
      this.endTime = record.endTime
      this.answerSubmit=true;
      this.answerCancel=true;
      this.answerFormShow = true;
    },
    answerCall(){
      if (this.answerSubmit && this.answerFormShow){
        this.visible=true;
      }else{
        this.answerPhone();
      }
    },
    // 决定应答
    answerPhone(){
      try{
        this.answer();
      }catch (error){
        this.callReminderShow=false;
        message.warning('exception occurred, please try again later');
        return ;
      }
      this.answerButton=false;
      this.startTimer();
      if (this.isPredictiveOutboundCall){
        this.answerFormShow = true;
      }
    },
      handleSubmit() {
          const that = this;
          that.confirmLoading = true;
          let formData = {};
          formData.customerClassification = this.customerClassification;
          formData.marker = this.isMarker;
          formData.remark = this.remark;
          formData.endTime = this.endTime;
          formData.callId = this.callId;
          this.sendFormDataToBackend(formData);
      },

      sendFormDataToBackend(data) {
          return new Promise((resolve, reject) => {
              axios({
                  method: 'post',
                  // url: 'http://127.0.0.1:8081/jeecg-boot/callRecord/updateRecord',
                  url: 'https://web.antgst.com/antgst/callRecord/updateRecord',
                  data: data, // 发送的数据
              }).then(response => {
                // 成功后显示提示消息
                if (response.data.success){
                  message.success('success')
                }else{
                  message.warning('fail')
                }
              }).catch(error => {
                  message.error('error');
              }).finally(()=>{
                this.handleCancel();
              })
          });
      },
    startTimer() {
      this.callDuration = 0;
      if (this.timeCall) {
        clearInterval(this.timeCall);
      }
      this.timeCall = setInterval(() => {
        this.callDuration++;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timeCall);
      this.timeCall = null;
    },
    showLogin() {
      this.showLoginDial = true;
    },
    handleLoginDialCancel() {
      if (!this.account && !this.password) {
        message.warning('please input account and password')
      }
      this.login();
    },
    cancelLoginDialCancel() {
      this.showLoginDial = false;
    },
    handleInfoDialCancel() {
      this.showInfoDial = false;
    },
    onClickMenu(event) {
      const key = event.key;
      if (key === '1') {
        this.showInfoDial = true;
      } else if (key === '2') {
        this.logout();
      }
    },

    onClickDialOutside(event) {
      console.log(event)
      this.showDial = false
    },
    onClickTransDialOutside(event) {
      this.showTransferDial = false;
    },
    handleAgentBarBtnClick(name) {
      if (name === 'login') {
        this.login();
      } else if (name === 'logout') {
        this.logout();
      } else if (name === 'answer') {
        this.answerCall();
      } else if (name === 'hangup') {
        this.hangup();
        this.stopTimer();
      } else if (name === 'makeCall') {
        this.makeCall(this.outNum)
      } else if (name === 'hold') {
        this.hold();
      } else if (name === 'unHold') {
        this.unHold();
      } else if (name === 'transfer') {
        this.transfer(this.transNum)
      }
    },

    login() {
      this.config.extNo = this.account;
      this.config.extPwd = this.password;
      this.init(this.config);
      setTimeout(() => {
        Ctibar.register()
      }, 200);
    },
    logout() {
      this.agentNo = '';
      Ctibar.unregister()
    },
    makeCall(phone) {
      if (this.isCall) {
        return;
      }
      if (this.callBtnDisable) {
        message.warning("please login first")
        return
      }
      console.info("makeCall number:" + phone)
      if (!phone || phone === '""' || phone === "''") {
        message.warning('please input phone number');
        return
      }
      phone = phone.replace(/\s+/g, "");
      console.log("trim makeCall number:" + phone)
      if (!phone || phone === '""' || phone === "''") {
        message.warning('please input phone number');
        return;
      }
      this.realDuration = null;
      this.isSwitchOn = false;
      this.makeCallTime = new Date().getTime();
      this.isCall = true;
      Ctibar.makecall(phone);
      this.showCall = true;
      this.customerNo=phone;
      this.startTimer();
    },
    // 来电提示
    callReminder(){
          this.isAnswered = true;
          this.answerButton = true;
          this.callReminderShow=true;
          this.startTimer();
          this.makeCallTime = new Date().getTime();
    },
    hold() {
      Ctibar.hold();
    },
    unHold() {
      Ctibar.unhold();
    },
    answer() {
      Ctibar.answer();
    },
    hangup() {
      try {
        Ctibar.hangup();
      }catch (error){
        this.callReminderShow=false;
        message.warning('exception occurred, please try again later')
      }
    },
    transfer(phone) {
      console.info("触发转接", phone)
      if (phone === "" || phone === undefined) {
        message.warning('please input phone number');
        return
      }
      Ctibar.transfer(phone);
    },
    //外呼拨号盘
    handleDialBtnClick(val) {
      this.outNum += val;
      this.$refs.outNumInput.focus();
    },
    //转接拨号盘
    handleTransDialBtnClick(val) {
      this.transNum += val;
      this.$refs.transNumInput.focus();
    },

    //参数为时间差秒数，返回这两个时间差并格式化
    computeTimeDiff(diff) {
      diff = Math.round(diff / 1000);
      let hour = Math.floor(diff / 3600).toString().padStart(2, '0');
      let min = Math.floor((diff - hour * 3600) / 60).toString().padStart(2, '0');
      let sec = (diff % 60).toString().padStart(2, '0');
      return hour + ':' + min + ':' + sec;
    },
    //重置时间
    restoreTime(origin) {
      clearInterval(this.timer);
      this.timerString = '00:00:00';
      this.timer = setInterval(() => {
        this.timerString = this.computeTimeDiff(new Date().getTime() - origin);
      }, 1000);
    },
    //状态变更回调
    stateEventListener(event, data) {
      //debug使用
      console.log('当前event为: ' + event + ', 当前data为: ' + JSON.stringify(data))

      this.agentStatus = event
      let origin = new Date().getTime();
      switch (event) {
        case "ERROR":
          console.log('error', data)
          message.error('error');
          break;

        case "REGISTER_FAILED":
          if (data.msg) {
            this.restoreTime(origin);
            this.isLoggedIn = true;
            if (data.msg.indexOf("Rejected") > -1) {
              message.error('Registration failed, please check the account password')
            } else if (data.msg.indexOf('Connection Error') && !this.config.extNo && !this.config.extPwd)  {
              message.error('Disconnected')
            }
          }
          break;

        case "CONNECTED":
          this.agentNo = data.localAgent
          this.isLoggedIn = false;
          this.restoreTime(origin);
          break;

        case "DISCONNECTED":
          this.restoreTime(origin);
          this.isLoggedIn = true;
          break;

        case "REGISTERED":
          this.restoreTime(origin);
          this.isLoggedIn = false;
          this.isCall = false;
          this.cancelLoginDialCancel();
          this.account=this.config.extNo;
          // 将帐户密码存入浏览器
          console.log('account', this.config.extNo, 'password', this.config.extPwd)
          if (this.config.extNo && this.config.extPwd) {
            localStorage.setItem('wssip_account', this.config.extNo);
            localStorage.setItem('wssip_password', this.config.extPwd);
            localStorage.setItem('wssip_login_time', new Date().getTime() + '')
          }
          message.success('Registration successful')
          break;

        case "UNREGISTERED":
          this.restoreTime(origin);
          this.isLoggedIn = true;
          localStorage.removeItem('wssip_account');
          localStorage.removeItem('wssip_password');
          message.success('Unregistered successfully')
          break;

        case "OUTGOING_CALL":
          this.customerNo = data.otherLegNumber;
          this.playCallRingMedia();
          this.restoreTime(origin);
          break;

        case "INCOMING_CALL":
          this.customerNo = data.transferNumber;
          //播放来电振铃音
          this.playRingMedia();
          this.restoreTime(origin);
          console.log("INCOMING_CALL_APP_ID:",data.APPID)
          if (data.APPID!=null && data.APPID.trim()==="PREDICTIVE_OUTBOUND_CALL"){
            this.isPredictiveOutboundCall=true;
          }else{
            this.isPredictiveOutboundCall=false;
          }
          if(data===undefined) {
            message.warning('INCOMING_CALL_ERROR')
            break;
          }
          else this.callReminder();
          this.callId = data.callId;
          break;

        case "IN_CALL":
          this.stopPlayCallRingMedia();
          this.stopPlayRingMedia();
          this.responseTime = origin;
          this.isSwitchOn = true;
          this.restoreTime(origin);
          break;

        case "CALL_END":
          this.stopPlayCallRingMedia();
          this.stopPlayRingMedia();
          //挂机铃声
          this.playHangupMedia()
          this.restoreTime(origin);
          this.isCall = false;
          this.showCall = false;
          this.endTime = new Date().getTime();
          this.callReminderShow=false;
          this.answerSubmit=true;
          if (data && data.msg) {
            this.callResult = data.msg
            message.warning('Call result: ' + data.msg)
          }
          if (this.responseTime) {
            this.realDuration = this.computeTimeDiff(origin - this.responseTime);
          }
          // 保存通话记录
          this.saveRecord();
          break;

        default:

      }
    },
    saveRecord(){
        const obj = {
            'callee': this.customerNo,
            'startTime': this.makeCallTime,
            'duration': this.realDuration ? this.realDuration : '',
            'callResult': this.isSwitchOn ? 'End of call' : this.callResult,
            'callId': this.isAnswered ? this.callId : '',
            'endTime': this.endTime ? this.endTime : '',
            'showEditButton': this.isPredictiveOutboundCall
      }
      this.isAnswered=false;
      this.isPredictiveOutboundCall=false;
      const record = localStorage.getItem('wssip_record')
      // 保存通话记录
      let arr = [];
      if (record) {
        let temp = JSON.parse(record);
        if (temp.length > 6) {
          temp.pop();
        }
        temp.unshift(obj);
        arr = temp
        localStorage.setItem('wssip_record', JSON.stringify(temp))
      } else {
        arr.push(obj);
        localStorage.setItem('wssip_record', JSON.stringify(arr))
      }
      this.recordList = arr;
    },
    //播放响铃声
    playCallRingMedia() {
      let callRingAudio = document.getElementById("callRingMediaAudioId")
      if (!callRingAudio) {
        const audio = document.getElementById('audio');
        callRingAudio = new Audio();
        callRingAudio.id = 'callRingMediaAudioId';
        callRingAudio.hidden = true;
        callRingAudio.src = 'wav/callRing.wav';
        callRingAudio.loop = true;
        audio.appendChild(callRingAudio);
      }
      callRingAudio.play();
    },
    // 停止播放响铃声
    stopPlayCallRingMedia() {
      const lastCallRingAudio = document.getElementById("callRingMediaAudioId")
      if (lastCallRingAudio) {
        document.getElementById('audio').removeChild(lastCallRingAudio);
      }
    },

    //播放挂机铃声
    playHangupMedia() {
      const _this = this;
      var hangupAudio = document.getElementById("hangupMediaAudioId")
      if (!hangupAudio) {
        const audio = document.getElementById('audio');
        hangupAudio = new Audio();
        hangupAudio.id = 'hangupMediaAudioId';
        hangupAudio.hidden = true;
        hangupAudio.src = 'wav/hangup.wav'
        audio.appendChild(hangupAudio);
      }
      hangupAudio.play();
    },
    //播放来电振铃
    playRingMedia() {
      const _this = this;
      _this.stopPlayRingMedia();

      var ringAudio = document.getElementById("ringMediaAudioId")
      if (!ringAudio) {
        const audio = document.getElementById('audio');
        ringAudio = new Audio();
        ringAudio.id = 'ringMediaAudioId';
        ringAudio.hidden = true;
        ringAudio.src = 'wav/ring.wav';
        ringAudio.loop = true;
        audio.appendChild(ringAudio);
      }
      ringAudio.play();
    },
    //停止播放来电振铃
    stopPlayRingMedia() {
      const _this = this;
      var ringAudio = document.getElementById("ringMediaAudioId");
      if (ringAudio) {
        document.getElementById('audio').removeChild(ringAudio);
      }
    },
    //初始化方法
    init() {
      Ctibar.initSDK(this.config)
    },
  },
  mounted() {
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const account = getParameterByName('account');
    const password = getParameterByName('password');
    const calleeNumber = getParameterByName('callee');
    if (account && password && calleeNumber) {
      this.config.extNo = account;
      this.config.extPwd = password;
      this.outNum = calleeNumber;
      this.init(this.config);
      setTimeout(() => {
        Ctibar.register()
      }, 200);

    } else if (localStorage.getItem('wssip_account') && localStorage.getItem('wssip_password')) {
      const loginTime = localStorage.getItem('wssip_login_time');
      console.log('loginTime', loginTime)
      if (loginTime && new Date().getTime() - Number(loginTime) > 1000 * 60 * 60 * 2 ) {
        return
      }
      this.config.extNo = localStorage.getItem('wssip_account');
      this.config.extPwd = localStorage.getItem('wssip_password');
      this.init(this.config);
      setTimeout(() => {
        Ctibar.register()
      }, 200);
    }
  }
}
</script>
<style>
#components-layout-demo-basic {
  text-align: center;
  background: #fafafa;
}

#components-layout-demo-basic .ant-layout-header {
  background: #7dbcea;
  color: #fff;
}

#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
}

#components-layout-demo-basic .ant-layout-sider {
  margin-top: 50px;
  color: black;
  background: #fff;
  line-height: 60px;
}

#components-layout-demo-basic .ant-layout-content {
  margin-top: 50px;
  margin-left: 40px;
  background: #fff;
  color: black;
  min-height: 600px;
  line-height: 0px;
  border-radius: 10px;
}

#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}

#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}

#answerForm {
  height: 600px;
  position: relative;
  top: 200px;
}

#answerForm:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}


</style>
